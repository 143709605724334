import { alpha, Container } from '@mui/system'
import React, { useEffect } from 'react'
import HomeLayout from '../Layouts/HomeLayout'

import milk_1 from "../img/daniel-sinoca-Qsa48he4JPc-unsplash.jpg";
import milk_2 from "../img/tao-yuan-puXS4VQGL_8-unsplash.jpg";
import bnuy_1 from "../img/ierc-K0zMceFs7tI-unsplash.jpg";
import astra_pose from "../img/astra_pose.png";

import useImagePreloader from '../hooks/useImagePreloader';
import { Parallax } from 'react-parallax';
import { Fade, Paper, Typography } from '@mui/material';
import { theme } from '..';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase-config';

const preloadSrcList: string[] = [
  milk_2,
  milk_1,
  bnuy_1,
  astra_pose,
];

function Home() {
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);
  useEffect(() => {
    logEvent(analytics, 'visit_site_home');
  }, []);
  return (
    <HomeLayout activeRoute={"/ing"} fadeNavIn>
      {!imagesPreloaded ? <>
        {/* empty */}
      </> : <>
        <div>
          <Fade in={imagesPreloaded} timeout={3000}><div>
            <Parallax bgImage={milk_2} blur={{ min: -1, max: 3 }} strength={500}>
              <div style={{ height: 1000 }}>
                <div style={{
                  // background: "white",
                  padding: 20,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)"
                }}><Typography variant="h2" textAlign={"center"} sx={{ fontWeight: "900" }}>Welcome to the<br /> Home of the<br /> <span style={{
                  background: "rgb(70,66,135)",
                  backgroundImage: "linear-gradient(90deg, rgba(70,66,135,1) 0%, rgba(109,240,201,1) 35%, rgba(0,212,255,1) 100%)",
                  backgroundSize: "100%",
                  backgroundRepeat: "repeat",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}>Cum Engineer</span></Typography></div>
              </div>
            </Parallax>
            <Parallax bgImage={milk_1} blur={{ min: -1, max: 3 }} strength={500}>
              <div style={{ height: 1000 }}>
                <div style={{
                  // background: "white",
                  padding: 20,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)"
                }}><Typography variant="h1" textAlign={"center"} sx={{ fontWeight: "900" }}>Excuse me,<br /> <span style={{
                  background: "rgb(70,66,135)",
                  backgroundImage: "linear-gradient(-90deg, rgba(70,66,135,1) 0%, rgba(109,240,201,1) 35%, rgba(0,212,255,1) 100%)",
                  backgroundSize: "100%",
                  backgroundRepeat: "repeat",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}>the <u>who</u>??</span></Typography></div>
              </div>
            </Parallax>
            <Parallax bgImage={bnuy_1} blur={{ min: -1, max: 3 }} strength={500}>
              <div style={{ height: 1000 }}>
                <div style={{
                  // background: "white",
                  padding: 20,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)"
                }}>
                  <div style={{
                    height: "1000"
                  }}>
                    {/* <img src={astra_pose} alt="Astra" /> */}
                    <div
                      style={{
                        position: "absolute",
                        // top: 0, left: 0, right: 0, bottom: 0,
                        top: "-55rem",
                        left: "-30rem",
                        // right: "0rem",
                        // background: "#000",
                        zIndex: 0
                      }}
                    >
                      <img src={astra_pose} alt="Astra" />
                    </div>
                    <div style={{
                      position: "absolute",
                      // left: 0, right: 0, bottom: 0,
                      top: "-1rem",
                      right: "-5rem",
                      // background: "#ff4444",
                      // padding: "3px 8px",
                      // color: "#fff",
                      zIndex: 1,
                    }}
                    >
                      <Paper
                        sx={{
                          padding: "1rem",
                          background: alpha(theme.palette.background.paper, 0.5),
                          width: "125%"
                        }}
                      >
                        <Typography variant="h3" gutterBottom>
                          OddPawsX
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          I am Astra, a rabbit furry who, for whatever reason, likes to make things that enable others to do horny things.
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          If you know me from my SFW presence on the web and expected that I was 100% wholesome, yet find yourself here, I am so, so sorry 😅
                        </Typography>
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
            </Parallax>
          </div></Fade>
        </div>
        <Container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Typography variant="h6">Image Credits</Typography>
          <Typography variant="caption">"Astra Undies" by @Hackfurs</Typography>
        </Container>
      </>}
    </HomeLayout>
  )
}

export default Home