import React from 'react'
import { RouteObject, createBrowserRouter, RouterProvider } from 'react-router-dom';
import EighteenPlus from './Pages/EighteenPlus';
import Home from './Pages/Home';
import Links from './Pages/Links';
import LinksOther from './Pages/LinksOther';

function Routing() {
  const RoutesConfig: Array<RouteObject> = [
    {
      path: "/ing",
      element: <Home />
    },
    {
      path: "/ing/links",
      element: <Links />
    },
    {
      path: "/ing/links/other",
      element: <LinksOther />
    },
    {
      path: "*",
      element: <EighteenPlus />
    }
  ];
  const router = createBrowserRouter(RoutesConfig);
  return (
    <RouterProvider router={router} />
  )
}

export default Routing