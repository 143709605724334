import { Fade, Typography } from '@mui/material'
import { Container } from '@mui/system'
import React, { useEffect } from 'react'
import HomeLayout from '../Layouts/HomeLayout'

import useImagePreloader from '../hooks/useImagePreloader';
import { Parallax } from 'react-parallax';
import Ion from '../Components/Ion';

import bg_1 from "../img/gilles-lambert-pb_lF8VWaPU-unsplash.jpg";
import bg_2 from "../img/sergey-zolkin-_UeY8aTI6d0-unsplash.jpg";
import { Link } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase-config';

function Links() {
  let links = [
    // { href: "https://ero.cum.engineer/", alt: "Erotic (furry) fiction, for if you like reading porn!", iconName: "book" },
    { href: "https://yiffhero.com/", alt: "All my fap hero/HMV content (new AND old), now on Telegram!", iconName: "archive" },
    { href: "https://bsky.app/profile/oddpa.ws", alt: "Bluesky", iconName: "cloud"},
    { href: "https://twitter.com/OddPawsX", alt: "Twitter", iconName: "logo-twitter" },
    // { href: "https://cum.gold/", alt: "Nudes, Lewds, and Porn (of me)", iconName: "body" },
    // { href: "https://t.me/AstraProxBot", alt: "Telegram", iconName: "chatbox-ellipses-outline" },
    // { href: "https://t.me/OddPawsXXXBunny", alt: "Telegram (DMs)", iconName: "chatbox-ellipses-outline" },
    // { href: "http://furtube.net/", alt: "FurTube", iconName: "play-outline", post: { search_value: "oddpawsx" } },
    // { href: "https://yhbackup.oddpa.ws/", alt: "Temporary archive of my Yiff Hero/HMV videos while Furtube is down", iconName: "archive" },
    { href: "/ing/links/other", alt: "Other", iconName: "code-slash", router: true }
  ];
  let bgs = [
    bg_1,
    bg_2
  ];
  const preloadSrcList: string[] = [...bgs];
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);
  useEffect(() => {
    logEvent(analytics, 'visit_site_links');
  }, []);
  return (
    <HomeLayout activeRoute={"/ing/links"} fadeNavIn title={"Links"}>
      <Fade in={imagesPreloaded} timeout={3000}>
        <Container fixed>
          <Typography variant="h2" sx={{ fontWeight: "600" }}>Links</Typography>
          {links.map((link: any, idx) => {
            let e;
            if (link.post) {
              e = (
                <form key={`linkId${idx}`} id={`linkId${idx}`} action={link.href} method="post">
                  <input type="hidden" name={"search_value"} value={link.post.search_value} />
                  <a title={link.alt} className='App-link' href="#" onClick={(event: any) => { // eslint-disable-line jsx-a11y/anchor-is-valid
                    const btn = document.getElementById(`linkId${idx}`) as HTMLFormElement;
                    btn.submit();
                  }} style={{
                    color: "inherit"
                  }}><Ion name={link.iconName} alt={link.alt} fontSize={"5rem"} /></a>
                </form>
              )
            } else {
              if(!link.router){
                e = (
                  <a
                    className="App-link"
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={`link-id-${idx}`}
                    title={link.alt}
                    style={{
                      color: "inherit"
                    }}
                  >
                    <Ion name={link.iconName} alt={link.alt} fontSize={"5rem"} />
                  </a>
                );
              } else {
                e = (
                  <Link
                    className="App-link"
                    to={link.href}
                    key={`link-id-${idx}`}
                    title={link.alt}
                    style={{
                      color: "inherit"
                    }}
                  >
                    <Ion name={link.iconName} alt={link.alt} fontSize={"5rem"} />
                  </Link>
                );
              }
            }
            return (
              <Parallax bgImage={bgs[idx%bgs.length]} blur={{ min: -1, max: 3 }} strength={500}>
              <div style={{ height: 300 }}>
                <div style={{
                  // background: "white",
                  padding: 20,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)"
                }}><Typography variant="h1" textAlign={"center"} sx={{ fontWeight: "900" }}><span style={{
                  background: "rgb(70,66,135)",
                  backgroundImage: "linear-gradient(90deg, rgba(70,66,135,1) 0%, rgba(109,240,201,1) 35%, rgba(0,212,255,1) 100%)",
                  backgroundSize: "100%",
                  backgroundRepeat: "repeat",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}>{e}</span>
                <br /><Typography variant="body1" style={{color: "white"}}>{link.alt}</Typography></Typography></div>
              </div>
            </Parallax>
            )
          })}
        </Container>
      </Fade>
    </HomeLayout>
  )
}

export default Links
