import { AppBar, Container, Toolbar, Typography, Box, IconButton, Menu, MenuItem, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react'
import { Link } from 'react-router-dom';
import { NavBarAppName } from '../../../Branding';
import { NavPages } from '../../../interfaces';
import { theme } from '../../..';
import CursorImitator from '../../../Components/CursorImitator';

function NavBarComponent({activeRoute}: {activeRoute?: any}) {
  const navPages: NavPages = [
    { title: "Home", path: "/ing" },
    { title: "Links", path: "/ing/links" },
  ];
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <AppBar position="static" color="transparent" elevation={0} sx={{ borderRadius: "1rem", marginTop: "1rem", marginBottom: "1rem" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AutoGraphIcon color="primary" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          <Typography
            variant="h6"
            noWrap
            // component="a"
            // href="/"
            component={Link}
            to={"/ing"}
            color="primary"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              // color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {/* {NavBarAppName} */}
            <div style={{width: "12rem"}}><CursorImitator content={[NavBarAppName]} nextLetterTime={200} /></div>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {navPages.map((page) => (
                <Link key={page.path} to={page.path} style={{ textDecoration: "none", color: "inherit" }}>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" sx={{
                      color: "inherit"
                    }}>{page.title}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          {/* <AutoGraphIcon color="primary" sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Typography
            variant="h5"
            noWrap
            // component="a"
            // href=""
            component={Link}
            to={"/ing"}
            color="primary"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              // color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {NavBarAppName}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {navPages.map((page) => {
              let isActive: boolean = page.path === activeRoute;
              return (
                <Button
                  component={Link}
                  to={page.path}
                  key={page.path}
                  onClick={handleCloseNavMenu}
                  variant={isActive ? "contained" : "text"}
                  sx={{ my: 2, color: isActive ? theme.palette.primary.contrastText : theme.palette.getContrastText(theme.palette.background.default), display: 'block' }}
                >
                  {page.title}
                </Button>
              )
            })}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default NavBarComponent