import { Fade, Paper, Typography } from '@mui/material'
import { alpha, Container } from '@mui/system'
import React, { useEffect } from 'react'
import HomeLayout from '../Layouts/HomeLayout'

import useImagePreloader from '../hooks/useImagePreloader';
import { Parallax } from 'react-parallax';

import bg_1 from "../img/klim-musalimov-XaAhZwxqY78-unsplash.jpg";
import bg_2 from "../img/isabella-bucur-delgado-CAX4FmLq0TI-unsplash.jpg";
import { theme } from '..';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase-config';

function LinksOther() {
  interface OtherLinks {
    href: string,
    text: string,
    newTab?: boolean,
    description?: string,
  }
  const links: Array<OtherLinks> = [
    { text: "My Captions", href: "https://cap.oddpa.ws", newTab: true, description: "If you like horny text on art" },
    // { text: "nutTRACKR", href: "https://nut.oddpa.ws", newTab: true, description: "When am I nutting?" },
    { text: "nutTRACKR", href: "https://nuttrackr.com/u/OddPawsX", newTab: true, description: "When am I nutting?" },
    { text: "Throne", href: "https://throne.me/u/oddpawsx", newTab: true, description: "Want to gift me things?" },
    // { text: "Fun Access Portal", href: "https://fap.oddpa.ws", newTab: true, description: "Share your reactions during Discord live streams~!" },
  ]
  let bgs = [
    bg_1,
    bg_2
  ];
  const preloadSrcList: string[] = [...bgs];
  const { imagesPreloaded } = useImagePreloader(preloadSrcList);
  useEffect(() => {
    logEvent(analytics, 'visit_site_links_other');
  }, []);
  return (
    <HomeLayout activeRoute={"/ing/links/other"} fadeNavIn title={"More Links"}>
      <Fade in={imagesPreloaded} timeout={3000}>
        <Container fixed>
          <Typography variant="h2" sx={{ fontWeight: "600" }}>More Links</Typography>
          {links.map((link, idx) => {
            let e = <a
              style={{ color: "#61dafb" }}
              href={`${link.href}`}
              target={link.newTab ? "_blank" : undefined}
              rel="noreferrer"
            >
              {`${link.text}`}{link.description ? `: ${link.description}` : ""}
            </a>;
            return (
              <Parallax bgImage={bgs[idx % bgs.length]} blur={{ min: -1, max: 3 }} strength={500}>
                <div style={{ height: 400 }}>
                  <div style={{
                    // background: "white",
                    padding: 20,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)"
                  }}>

                    <Paper sx={{ padding: "2rem", borderRadius: "2rem", background: alpha(theme.palette.background.paper, 0.75) }}>
                      <Typography variant="h6" textAlign={"center"} sx={{ fontWeight: "900" }}><span style={{
                        background: "rgb(70,66,135)",
                        backgroundImage: "linear-gradient(90deg, rgba(70,66,135,1) 0%, rgba(109,240,201,1) 35%, rgba(0,212,255,1) 100%)",
                        backgroundSize: "100%",
                        backgroundRepeat: "repeat",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }}>{e}</span></Typography>
                    </Paper>

                  </div>
                </div>
              </Parallax>
            )
          })}
        </Container>
      </Fade>
    </HomeLayout>
  )
}

export default LinksOther