import { Button, Fade } from '@mui/material'
import { logEvent } from 'firebase/analytics';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { analytics } from '../firebase-config';

function EighteenPlus() {
  const [remaining, setRemaining] = useState<number>(3);
  const navigate = useNavigate();
  const clickHandler = () => {
    let r = remaining;
    if(r === 0) {
      return
    };
    r = r - 1;
    setRemaining(r);
    if(r === 0){
      const timeout = setTimeout(() => {
        navigate("/ing")
      }, 3000);
      return () => clearTimeout(timeout);
    }
  };
  useEffect(() => {
    logEvent(analytics, 'visit_site_landing');
  }, []);
  const title = remaining > 0 ? `Press the button ${remaining} more time${remaining !== 1 && "s"} to Enter (18+)` : "Loading...";
  useEffect(() => {
    if (title) {
      document.title = title;
    } else {
      document.title = "Undefined";
    }
  }, [title]);
  return (
    <div style={{
      minWidth: "100%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"

    }}><div style={{ textAlign: "center" }}>
      <Fade in={remaining !== 0} timeout={3000}>
        <Button
          onClick={clickHandler}
          disabled={remaining === 0}
        >
          Press {remaining} more time{remaining !== 1 && "s"} to Enter (18+)
        </Button>
      </Fade>
    </div></div>
  )
}

export default EighteenPlus